<template>
  <div>
    <v-row>
      <v-col>
        <v-switch v-model="type" label="期待回复" color="info" hide-details></v-switch>
      </v-col>
    </v-row>
    <v-row v-show="type">
      <v-col  class="pb-0">
        <v-text-field v-model="q.email" label="E-mail" outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col class="pt-0">
        <v-textarea label="请输入您的留言内容" v-model="q.message_detail" auto-grow outlined rows="10" row-height="15"></v-textarea>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-alert
          v-show="alert.visiable"
            outlined
            dismissible
            :type="alert.type"
            text
            >
            {{alert.msg}}
            </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn class="float-right" @click="postMsg">提交</v-btn>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12">
        <v-card flat outlined class="rounded-lg" v-for="item of messages" :key="item.id" style="margin-top:1rem;">
          <v-card-text>
            <p>{{item.message_detail}}</p>
            <p class="text-right">{{item.create_time}}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // @ is an alias to /src
  //import HelloWorld from '@/components/HelloWorld.vue'


  export default {
    name: 'Home',
    components: {
      //HelloWorld
    },
    data: function () {
      return {
        type: false,
        q:{
          email:"",
          message_detail:""
        },
        alert:{
          visiable:false,
          type:"error",
          msg:"ssssss"
        },
        messages:[]
      }
    },
    methods:{
      postMsg(){
        if(this.q.message_detail==""){
          this.alert_warning("留言内容不可为空")
          return
        }
        this.axios.post(this.$store.state.base_url +"/message/front",this.q).then(res=>{
          if(res.data.code==200){
            console.log(res)
            this.alert_success("留言提交成功")
          }else{
            this.alert_error("留言失败")
          }
        }).catch(()=>{
          this.alert_error("留言失败")
        })
      },
      getMesages(){
        this.axios.get(this.$store.state.base_url +"/message/front/list").then(res=>{
          if(res.data.code==200){
            this.messages = res.data.data
            console.log(res.data.data)
          }else{
            this.alert_error("获取留言失败")
          }
        }).catch(()=>{
          this.alert_error("获取留言失败")
        })
      },
      alert_success(msg){
        this.alert.visiable = true
        this.alert.type = "success"
        this.alert.msg = msg
      },
      alert_error(msg){
        this.alert.visiable = true
        this.alert.type = "error"
        this.alert.msg = msg
      },
      alert_warning(msg){
        this.alert.visiable = true
        this.alert.type = "warning"
        this.alert.msg = msg
      },
    },
    created(){
      this.$emit("hideBackToTop")
      this.getMesages()
    }
  }
</script>